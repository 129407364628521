import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from 'components/layout';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withPreview } from 'gatsby-source-prismic';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  TitleContentColumn,
  RelatedProjects,
  CustomContactForm,
} from 'components/slices';
import Link from 'gatsby-link';
import { Helmet } from 'react-helmet';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 800px;
  position: relative;
  overflow: hidden;
`;

function Project(props) {
  const { data } = props;
  const doc = data.prismicProject;
  if (!doc) return null;
  return (
    <Layout>
      {doc.data.metadata && doc.data.metadata[0] && (
        <Helmet>
          {doc.data.metadata[0].title && doc.data.metadata[0].title[0] && (
            <title>{doc.data.metadata[0].title[0].text}</title>
          )}
          {doc.data.metadata[0].description &&
            doc.data.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.data.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.data.metadata[0].keywords &&
            doc.data.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.data.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <Container maxWidth="xl">
        <Box className="spaced-grid-wrapper">
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              {doc.data.project_images &&
                doc.data.project_images[0] &&
                doc.data.project_images[0].image &&
                doc.data.project_images[0].image.url && (
                  <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    useKeyboardArrows
                  >
                    {doc.data.project_images.map((item, key) => {
                      return (
                        <div key={key}>
                          <Background image={item.image.url}>
                            <Box
                              height="100%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                            ></Box>
                          </Background>
                        </div>
                      );
                    })}
                  </Carousel>
                )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                pt={{ xs: 0, md: 10 }}
                display="flex"
                flexDirection={{ xs: 'row', md: 'column' }}
                flexWrap="wrap"
                height="100%"
                justifyContent="space-between"
              >
                <Box mb={4} width={{ xs: '100%', md: '100%' }}>
                  <Typography variant="h4">
                    {doc.data.project_title.text}
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {doc.data.project_location.text}
                  </Typography>
                  {doc.data.project_status.document &&
                    doc.data.project_status.document.data &&
                    doc.data.project_status.document.data.status && (
                      <Typography variant="body1">
                        {doc.data.project_status.document.data.status}
                      </Typography>
                    )}
                </Box>
                <Box mb={4} width={{ xs: '100%', md: '100%' }}>
                  {doc.data.project_details &&
                    doc.data.project_details[0] &&
                    doc.data.project_details[0].detail &&
                    doc.data.project_details[0].detail.text && (
                      <React.Fragment>
                        <Typography className="text-uppercase full-underline">
                          DETAILS
                        </Typography>
                        <Box pt={4}>
                          {doc.data.project_details.map((item, key) => {
                            return (
                              <Box
                                key={key}
                                display="flex"
                                alignItems="center"
                                mb={2}
                              >
                                <Box className="icon-align" mr={2}>
                                  <img
                                    className="icon-xsml"
                                    src={item.icon.url}
                                    alt={item.icon.alt}
                                  />
                                </Box>
                                <Typography variant="body2">
                                  {item.detail.text}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </React.Fragment>
                    )}
                </Box>
                <Box mb={4} width="100%">
                  {doc.data.project_downloads &&
                    doc.data.project_downloads[0] &&
                    doc.data.project_downloads[0].download &&
                    doc.data.project_downloads[0].download.url && (
                      <React.Fragment>
                        <Typography className="text-uppercase full-underline">
                          Downloads
                        </Typography>
                        <Box pt={4}>
                          {doc.data.project_downloads.map((item, key) => {
                            return (
                              <Link
                                key={key}
                                to={item.download.url}
                                target="_blank"
                                className="download-link"
                              >
                                <Box display="flex" alignItems="center" mb={2}>
                                  <Box
                                    className="icon-align"
                                    mr={2}
                                    display="flex"
                                    justifyContent="center"
                                  >
                                    <GetAppIcon />
                                  </Box>
                                  <Typography variant="body2">
                                    {item.title.text}
                                  </Typography>
                                </Box>
                              </Link>
                            );
                          })}
                        </Box>
                      </React.Fragment>
                    )}
                </Box>

                <Box mb={4} width="100%">
                  <Link to="/contact-us" className="btn btn-default">
                    ENQUIRE
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {doc.data.body && (
          <React.Fragment>
            {doc.data.body.map((item, i) => {
              switch (item.slice_type) {
                case 'title__content_col':
                  return (
                    <div key={i}>
                      <div className="spacer">
                        <TitleContentColumn data={item.primary} />
                      </div>
                    </div>
                  );
                case 'related_projects':
                  return (
                    <div key={i}>
                      <div className="spacer">
                        <RelatedProjects items={item.items} />
                      </div>
                    </div>
                  );
                case 'custom_contact_form':
                  return (
                    <div key={i}>
                      <div className="spacer">
                        <CustomContactForm
                          data={item.primary}
                          items={item.items}
                        />
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </React.Fragment>
        )}
      </Container>
    </Layout>
  );
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Project);

export const query = graphql`
  query ProjectPageQuery($uid: String!) {
    prismicProject(id: { eq: $uid }) {
      id
      data {
        metadata {
          description {
            text
          }
          keywords {
            text
          }
          title {
            text
          }
        }
        featured_image {
          alt
          url
        }
        project_title {
          html
          raw
          text
        }
        project_location {
          html
          raw
          text
        }
        project_status {
          document {
            ... on PrismicProjectStatus {
              id
              data {
                status
              }
            }
          }
        }
        project_images {
          caption {
            html
            raw
            text
          }
          image {
            alt
            url
          }
          title {
            html
            raw
            text
          }
        }
        project_details {
          detail {
            html
            raw
            text
          }
          icon {
            alt
            url
          }
        }
        project_downloads {
          title {
            html
            raw
            text
          }
          download {
            url
          }
        }
        body {
          ... on PrismicProjectBodyTitleContentCol {
            slice_type
            primary {
              caption {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicProjectBodyRelatedProjects {
            id
            slice_type
            items {
              project {
                uid
                document {
                  ... on PrismicProject {
                    id
                    data {
                      featured_image {
                        url
                      }
                      project_status {
                        document {
                          ... on PrismicProjectStatus {
                            id
                            data {
                              status
                            }
                          }
                        }
                      }
                      project_location {
                        html
                        raw
                        text
                      }
                      project_title {
                        html
                        raw
                        text
                      }
                    }
                  }
                }
                type
                url
              }
            }
          }
          ... on PrismicProjectBodyCustomContactForm {
            slice_type
            primary {
              caption {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            items {
              field_name
              field_types
            }
          }
        }
      }
    }
  }
`;
